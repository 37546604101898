// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {pE11MvWVg: {hover: true, pressed: true}};

const cycleOrder = ["pE11MvWVg"];

const variantClassNames = {pE11MvWVg: "framer-v-jnwhbp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({color, height, id, name, tap, width, ...props}) => { return {...props, B4EhOI7sy: tap ?? props.B4EhOI7sy, DnHEwUTcR: name ?? props.DnHEwUTcR ?? "ArrowLeft", MYHLPhiUj: color ?? props.MYHLPhiUj ?? "var(--token-b1fb229f-a8e6-4640-b958-ea29fe1a7f0e, rgb(25, 21, 78)) /* {\"name\":\"Text\"} */"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;name?: string;color?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DnHEwUTcR, MYHLPhiUj, B4EhOI7sy, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "pE11MvWVg", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1k5u0m6 = activeVariantCallback(async (...args) => {
if (B4EhOI7sy) {
const res = await B4EhOI7sy(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-d5MxV", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-jnwhbp", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"pE11MvWVg"} onTap={onTap1k5u0m6} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}} variants={{"pE11MvWVg-hover": {backgroundColor: "rgba(255, 255, 255, 0.8)"}, "pE11MvWVg-pressed": {backgroundColor: "rgba(255, 255, 255, 0.8)"}}} {...addPropertyOverrides({"pE11MvWVg-hover": {"data-framer-name": undefined}, "pE11MvWVg-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-146m1zv-container"} layoutDependency={layoutDependency} layoutId={"KmhvnlW4H-container"} style={{opacity: 1}} variants={{"pE11MvWVg-hover": {opacity: 0.8}, "pE11MvWVg-pressed": {opacity: 0.6}}}><Phosphor color={MYHLPhiUj} height={"100%"} iconSearch={"House"} iconSelection={DnHEwUTcR} id={"KmhvnlW4H"} layoutId={"KmhvnlW4H"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-d5MxV [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-d5MxV .framer-1hcv5jb { display: block; }", ".framer-d5MxV .framer-jnwhbp { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 40px; justify-content: center; overflow: hidden; padding: 10px 10px 10px 10px; position: relative; width: 40px; will-change: var(--framer-will-change-override, transform); }", ".framer-d5MxV .framer-146m1zv-container { flex: 1 0 0px; height: 100%; position: relative; width: 1px; }", ".framer-d5MxV .framer-v-jnwhbp .framer-jnwhbp { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-d5MxV .framer-jnwhbp { gap: 0px; } .framer-d5MxV .framer-jnwhbp > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-d5MxV .framer-jnwhbp > :first-child { margin-left: 0px; } .framer-d5MxV .framer-jnwhbp > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"mZTVnwtgu":{"layout":["fixed","fixed"]},"l728cytYK":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DnHEwUTcR":"name","MYHLPhiUj":"color","B4EhOI7sy":"tap"}
 */
const FramermlHbdW8Hq: React.ComponentType<Props> = withCSS(Component, css, "framer-d5MxV") as typeof Component;
export default FramermlHbdW8Hq;

FramermlHbdW8Hq.displayName = "Icon Button";

FramermlHbdW8Hq.defaultProps = {height: 40, width: 40};

addPropertyControls(FramermlHbdW8Hq, {DnHEwUTcR: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "ArrowLeft", hidden: undefined, title: "Name"}, MYHLPhiUj: {defaultValue: "var(--token-b1fb229f-a8e6-4640-b958-ea29fe1a7f0e, rgb(25, 21, 78)) /* {\"name\":\"Text\"} */", title: "Color", type: ControlType.Color}, B4EhOI7sy: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramermlHbdW8Hq, [...PhosphorFonts])